import React from 'react'

import { Content, Footer, Pivot } from './components';

import './App.css';

const App = () => (
    <div>
      <Pivot />
      <Content />
      <Footer />
    </div>
  );

export default App;